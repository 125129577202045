import $ from 'jquery'
import 'bootstrap/js/dist/tooltip'

// Turn our custom tooltips (created in Django) into Bootstrap tooltips
const tooltips: NodeListOf<HTMLSpanElement> = document.querySelectorAll('.js-tooltip')
;[].forEach.call(tooltips, (tooltipEl: HTMLSpanElement) => {
  tooltipEl.innerHTML = ''
  tooltipEl.dataset.toggle = 'tooltip'
  tooltipEl.dataset.title = tooltipEl.dataset.content
  tooltipEl.classList.add('tooltip-toggler', 'active')
})

document.addEventListener('DOMContentLoaded', () => {
  const tooltipDelay = 400
  let tooltipTimer: ReturnType<typeof setTimeout> | null = null

  const clearTooltipTimeout = () => {
    if (tooltipTimer) {
      clearTimeout(tooltipTimer)
      tooltipTimer = null
    }
  }

  // Initialize all tooltips
  $('[data-toggle="tooltip"]').tooltip({
    html: true,
    trigger: 'manual',
    delay: { show: 0, hide: tooltipDelay },
  })

  $(document)
    .on('mouseenter', '[data-toggle="tooltip"]', function () {
      clearTooltipTimeout()
      $('.tooltip').tooltip('hide') // Hide other tooltips
      $(this).tooltip('show')
    })
    .on('mouseleave', '[data-toggle="tooltip"]', function (e) {
      tooltipTimer = setTimeout(() => {
        if (!$('.tooltip:hover').length) {
          $(e.currentTarget).tooltip('hide')
        }
      }, tooltipDelay)
    })
    .on('mouseenter', '.tooltip', clearTooltipTimeout)
    .on('mouseleave', '.tooltip', function (e) {
      tooltipTimer = setTimeout(() => {
        if (!$('[data-toggle="tooltip"]:hover').length) {
          $(e.currentTarget).tooltip('hide')
        }
      }, tooltipDelay)
    })
})
