import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { apiGet } from '~src/api/client'
import { IFinancialProduct } from '~src/api/types/configurator'
import CreditWarning from '~src/apps/Configurator/components/CreditWarning'
import FinanceTable from '~src/apps/Occasion/FinanceTable'
import RadioPillInput from '~src/common/RadioPillInput'
import RangeInput from '~src/common/RangeInput'
import Tooltip from '~src/common/Tooltip'
import { useModel, useRootState } from '~src/hooks/store'
import { setFinancialProducts, setUsingFinancialPlan } from '~src/store/api/actions'
import { updateBasicConfiguration } from '~src/store/configuration/actions'
import { setTradeInModalStatus } from '~src/store/trade-in/actions'
import { unique } from '~src/utils/collection'
import { currencyFormatter } from '~src/utils/number-formatter'
import {
  DEFAULT_FINAL_PAYMENT_PERCENTAGE,
  findFinancialConfig,
  findPaymentPlan,
  getDefaultDownpaymentPercentage,
  getMaxFinalCreditPayment,
  getMonthlyCreditPayment,
} from '~src/utils/price'

const MAX_DOWNPAYMENT_MARGIN = 2500

const CalculateMonthlyPayment = ({
  financeApiUrl,
  apiRootUrl,
}: {
  financeApiUrl: string
  apiRootUrl: string
}): JSX.Element => {
  const dispatch = useDispatch()
  const model = useModel()
  const {
    configuration,
    api: { financialProducts, occasion },
  } = useRootState()
  const totalPrice = Number(occasion.price_online)
  const credit = totalPrice - configuration.basic.downPayment
  const product =
    financialProducts && findPaymentPlan(financialProducts, configuration.productGroup, model)
  const financialConfig = product && findFinancialConfig(credit, product, configuration)

  // Calculate duration months options based on age of car
  // The max age a car can be to pay off a financial loan
  const maxAgeOfCar = 15
  const ageOfCar = new Date().getFullYear() - occasion?.year
  const monthsLeftToFinance = (maxAgeOfCar - ageOfCar) * 12
  const durationOptions = unique(
    product?.configs?.map((i) => i.duration).sort((a, b) => a - b)
  ).filter((option) => option <= monthsLeftToFinance)

  useEffect(() => {
    if (!durationOptions.includes(configuration.basic.durationMonths)) {
      dispatch(
        updateBasicConfiguration({ durationMonths: durationOptions[durationOptions.length - 1] })
      )
    }
  }, [])

  // Slider values and min/max restrictions
  const maxDownPayment = totalPrice - MAX_DOWNPAYMENT_MARGIN
  const minDownPayment = Math.round(Math.max(0, totalPrice - Number(financialConfig.max_credit)))
  const maxFinalPayment = getMaxFinalCreditPayment(credit, financialConfig)
  const monthlyPayment = getMonthlyCreditPayment(
    totalPrice - configuration.basic.downPayment,
    Number(financialConfig.interest_rate) / 100,
    configuration.basic.finalPayment,
    configuration.basic.durationMonths
  )

  useEffect(() => {
    if (!financialProducts.length) {
      void apiGet<IFinancialProduct[]>(financeApiUrl).then((r) => {
        dispatch(setFinancialProducts(r.data))
      })
    }
  }, [financialProducts])

  useEffect(() => {
    dispatch(
      updateBasicConfiguration({
        downPayment: Math.floor(
          totalPrice * getDefaultDownpaymentPercentage(configuration.productGroup)
        ),
        finalPayment: credit * DEFAULT_FINAL_PAYMENT_PERCENTAGE,
      })
    )
  }, [])

  // finalPayment is based on downpayment and duration, limit if necessary
  if (configuration.basic.finalPayment > maxFinalPayment) {
    dispatch(
      updateBasicConfiguration({
        finalPayment: Math.floor(maxFinalPayment),
      })
    )
  }

  if (!financialConfig) {
    return <div>Bezig met ophalen van financiering opties...</div>
  }

  return (
    <>
      <div className="mt-5 mt-md-0 mb-5">
        <CreditWarning />
      </div>

      <h2>Bereken jouw maandbedrag</h2>

      <p>
        Zie je jezelf ook al rijden in een Suzuki? Maar wil je de aanschafprijs liever gespreid
        betalen? Met het Suzuki Betaalplan doe je dat zonder financiële verrassingen: Je leent een
        vast bedrag – al vanaf € 2.500,-* - tegen een vaste rente. En je bepaalt zelf hoeveel je
        elke maand afbetaalt óf hoelang je looptijd is. Alle reden dus om snel jouw Suzuki te
        kiezen.
      </p>

      <h5 className="mt-5 mb-4">Aanbetalen</h5>

      <div className="row mb-4 align-items-center">
        <div className="col-12 col-md-8 mb-3 mb-md-0">
          <div className="text-medium mb-2">Hoeveel wil je aanbetalen?</div>

          <RangeInput
            name="downPayment"
            extraClassName="reversed"
            value={configuration.basic.downPayment}
            steps={{
              min: minDownPayment,
              max: maxDownPayment,
              size: 1,
            }}
            getLabel={() => 'max. ' + currencyFormatter.format(maxDownPayment)}
            onChange={(v) =>
              dispatch(
                updateBasicConfiguration({
                  downPayment: v,
                })
              )
            }
          >
            <span className="input-range-value">{currencyFormatter.format(0)}</span>
          </RangeInput>
        </div>

        <div className="form-group col-12 col-md-4 mb-0">
          <input
            className="form-control input-text"
            type="text"
            min="0"
            value={'€ ' + Math.round(configuration.basic.downPayment)}
            onChange={(e) =>
              dispatch(
                updateBasicConfiguration({
                  downPayment: Math.min(Number(e.target.value.replace(/\D+/g, '')), maxDownPayment),
                })
              )
            }
          />
          <div className="invalid-feedback"></div>
        </div>
      </div>

      <div className="mb-5">
        Je kunt ook de uiteindelijke inruilwaarde van je huidige auto als aanbetaling opnemen. Bij
        een aanbetaling die minder is dan je inruilbedrag wordt het resterende bedrag uitbetaald.
      </div>

      <div className="mb-5">
        <h5>Looptijd &amp; Slottermijn</h5>

        <div>
          <span className="text-medium mb-2">
            Binnen welke tijd wil jij de financiering afbetalen?{' '}
          </span>
          <Tooltip text="De financiering moet afgelost zijn als de auto 15 jaar oud is. Mogelijk zijn daardoor de keuzes in looptijd beperkt." />
        </div>

        <div className="pill-full-width">
          <RadioPillInput
            name="durationMonths"
            value={configuration.basic.durationMonths}
            onChange={(e) =>
              dispatch(
                updateBasicConfiguration({
                  durationMonths: e,
                })
              )
            }
            options={durationOptions.map((value) => ({ value, label: `${value} maanden` }))}
          ></RadioPillInput>
        </div>
      </div>

      <div className="row mb-5 align-items-center">
        <div className="col-12 col-md-8 mb-3 mb-md-0">
          <div>
            <span className="text-medium mb-2">
              Hoeveel wil je betalen in je <br className="d-sm-none" />
              slottermijn?{' '}
            </span>

            <Tooltip text="De slottermijn is het bedrag dat nog open blijft staan aan het einde van de looptijd. Tijdens de looptijd betaal je alleen rente over dit bedrag en geen aflossing. De slottermijn is maximaal gelijk aan de waarde van je auto. Dus als je de auto verkoopt betaal je met dat bedrag het slottermijn af." />
          </div>

          <RangeInput
            name="finalPayment"
            value={configuration.basic.finalPayment}
            extraClassName="reversed"
            steps={{
              min: Number(financialConfig.min_final_term),
              max: maxFinalPayment,
              size: 1,
            }}
            getLabel={() => 'max. ' + currencyFormatter.format(Math.floor(maxFinalPayment))}
            onChange={(v) =>
              dispatch(
                updateBasicConfiguration({
                  finalPayment: v,
                })
              )
            }
          >
            <span className="input-range-value">{currencyFormatter.format(0)}</span>
          </RangeInput>
        </div>

        <div className="form-group col-12 col-md-4 mb-0">
          <input
            className="form-control input-text"
            type="text"
            min="0"
            value={'€ ' + Math.round(configuration.basic.finalPayment)}
            onChange={(e) =>
              dispatch(
                updateBasicConfiguration({
                  finalPayment: Math.min(
                    Number(e.target.value.replace(/\D+/g, '')),
                    maxFinalPayment
                  ),
                })
              )
            }
          />
          <div className="invalid-feedback"></div>
        </div>
      </div>

      <div className="occasion-finance__price-block">
        <h5>Te financieren bedrag</h5>

        <div className="d-md-flex">
          <div className="mr-2">Jouw Suzuki {occasion.model} rijklaar</div>
          <h5 className="ml-auto mb-0">{currencyFormatter.format(totalPrice)}</h5>
        </div>

        <hr className="my-3" />

        <div className="d-md-flex">
          <div className="mr-2 mb-0">Te financieren bedrag</div>
          <h5 className="ml-auto">
            {currencyFormatter.format(totalPrice - configuration.basic.downPayment)}
          </h5>
        </div>
      </div>

      <div className="occasion-finance__monthly-price mb-4">
        <h4>Jouw maandbedrag</h4>

        <div className="d-md-flex mb-3">
          <div className="mr-2">
            Suzuki betaalplan{` `}
            <Tooltip text="Het Suzuki betaalplan is een lening waarbij je per maand afbetaalt. Je kiest zelf hoeveel maanden je daarover wilt doen. Tijdens de looptijd veranderen de rente en het maandbedrag niet." />
          </div>

          <h5 className="ml-auto mb-0">
            {currencyFormatter.format(monthlyPayment)} <span className="text-lowercase">p/m*</span>
          </h5>
        </div>

        <p className="text-small text-muted mb-3">
          Bij een looptijd van {configuration.basic.durationMonths} maanden, een aanbetaling van €
          {configuration.basic.downPayment},- en een slottermijn van €
          {Math.round(configuration.basic.finalPayment)},-
        </p>

        <button
          className="button button--primary cta"
          onClick={() => {
            dispatch(setUsingFinancialPlan(true))
            dispatch(setTradeInModalStatus(null))
          }}
        >
          Financiering toevoegen
        </button>
      </div>

      <FinanceTable apiRootUrl={apiRootUrl} />
    </>
  )
}

export default CalculateMonthlyPayment
