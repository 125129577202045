import React, { useEffect, useRef } from 'react'

type ModelViewerInstance = {
  setColor?: (color: { name: string; color: string }) => void
  resizeViewer?: () => void
}

type ModelViewerProps = {
  model: string
  color: string
  secondaryColor?: string
  hidden?: boolean
}

export const ModelViewer = ({ model, color, secondaryColor, hidden }: ModelViewerProps) => {
  console.log({
    model,
    color,
    secondaryColor,
  })

  const divRef = useModelViewer({ model, color, secondaryColor })

  useUpdateColor(color, secondaryColor, divRef.current)
  useResizeModelViewer(hidden, divRef.current)

  return <div ref={divRef} />
}

type CreateModelViewerProps = ModelViewerProps & {
  element: HTMLDivElement
}

const createModelViewer = ({ model, element, color, secondaryColor }: CreateModelViewerProps) => {
  // @ts-ignore
  // https://api.270degrees.nl/docs/index.html#/?id=implicit-vs-explicit-loading
  TSDViewer.create(element, {
    model: model,
    plugins: 'customiser',
    loaderColor: '#de0039, #de0039',
    loaderLogo: false,
    loaderByline: '',
    cameraPosition: '30.252, 87.329',
    cameraZoomLevel: 0.618,
    rotateSpeed: 0.5,
    forceLoad: true,
    opacity: 0,

    onLoadComplete: () => {
      // TDSViewer.create() adds additional methods to the element
      const loadedElement = element as HTMLDivElement & ModelViewerInstance

      // Initial color setting
      loadedElement.setColor({ name: 'exterior', color: `#${color}` })
      loadedElement.setColor({ name: 'roof', color: `#${secondaryColor ? secondaryColor : color}` })
    },
  })
}

const useModelViewer = ({ model, color, secondaryColor }: ModelViewerProps) => {
  const divRef = useRef<HTMLDivElement & ModelViewerInstance>(null)

  useEffect(() => {
    if (!divRef.current || divRef.current?.setColor || !model) {
      return
    }

    createModelViewer({
      model,
      element: divRef.current,
      color,
      secondaryColor,
    })
  }, [model, divRef.current])

  return divRef
}

const useUpdateColor = (
  color: string,
  secondaryColor: string,
  modelViewer?: ModelViewerInstance
) => {
  useEffect(() => {
    if (modelViewer?.setColor) {
      modelViewer.setColor({ name: 'exterior', color: `#${color}` })
      modelViewer.setColor({ name: 'roof', color: `#${secondaryColor ? secondaryColor : color}` })
    }
  }, [color, secondaryColor, modelViewer?.setColor])
}

const useResizeModelViewer = (hidden?: boolean, modelViewer?: ModelViewerInstance) => {
  useEffect(() => {
    if (modelViewer?.resizeViewer && !hidden) {
      setTimeout(() => {
        modelViewer.resizeViewer()
      }, 100)
    }
  }, [hidden, modelViewer?.resizeViewer])
}
