/***
 * @name Side panel
 *
 * @description
 */

import React, { useEffect, useRef, useState } from 'react'
import DealerSearch from '~src/apps/DealerMap/components/DealerSearch'
import { INavigate, ISidePanel } from '~src/apps/DealerMap/types'
import { bodyScrollIntoView } from '~src/utils/scrollTo'
import DealerFilter from './DealerFilterMotor'
import DealerInfo from './DealerInfo'
import DealerList from './DealerList'
import SidePanelHeader from './SidePanelHeader'

const $DealerMap: HTMLElement = document.querySelector('.js-dealer-map')

const SidePanel: React.FC<ISidePanel & INavigate> = ({
  dealer = null,
  overviewUrl = null,
  enableSearch = false,
  locations,
  onDealerSelect,
  onDealerSearch,
  onGeolocationRequest,
  onGoHome,
  showDistances,
  showInfo,
  showBackButton,
  viewMode,
  googleError,
  productGroup,
  filterOptions,
  filter,
  setFilter,
  formType,
  queryStringFilter,
  dealerDetailUrl,
  subtitle,
  configuratorQueryString,
  isModal,
}) => {
  //
  // == State
  const [show, setShow] = useState(false)
  const dealerListRef = useRef<HTMLDivElement>()
  const dealerInfoRef = useRef<HTMLDivElement>()

  //
  // == Lifecycle hook: On mount -> show panel after a short period of time
  useEffect(() => {
    const showTimeout = setTimeout(() => {
      setShow(true)
    }, 500)

    return () => {
      clearTimeout(showTimeout)
    }
  }, [])

  // == Life-cycle hook:
  useEffect(() => {
    if (locations.length > 1 && window.innerWidth < 768 && viewMode !== 'viewMode') {
      if (showInfo) {
        setTimeout(() => {
          if ($DealerMap) {
            void bodyScrollIntoView($DealerMap).then(() => {
              if (dealerInfoRef.current) {
                const bounds = dealerInfoRef.current.getBoundingClientRect()
                if (dealerListRef?.current?.style) {
                  dealerListRef.current.style.height = `${bounds.height}px`
                }
              }
            })
          }
        }, 250)
      } else {
        if (dealerListRef?.current?.style) {
          dealerListRef.current.style.height = null
        }
      }
    }
  }, [showInfo])

  const dealerIndex = locations.findIndex((i) => i.id === dealer?.id)
  const dealersFound = locations?.length
  const queryStringMotorfilters = ['motor_type_atv', 'motor_type_road', 'motor_type_cross']

  const isFilteringMotor =
    !!queryStringFilter &&
    !!queryStringFilter?.flat().find((i) => queryStringMotorfilters.includes(i))

  //
  // Render
  return (
    <div className={`side-panel ${show ? 'show-panel' : ''}`}>
      <div
        className={`side-panel__container ${showInfo && productGroup === 'car' ? 'show-page' : ''}`}
      >
        <div className="dealer-list side-panel__page" ref={dealerListRef}>
          <div className="dealer-list__header">
            {subtitle && <h6 className="font-weight-light mb-1 mb-lg-2">{subtitle}</h6>}
            <div className="dealer-list__title">
              <h1 className="h3 mb-0">Vind je dealer</h1>
              <span className="dealer-list__amount-found">
                {dealersFound === 0 && ''}
                {dealersFound > 0 && (
                  <>
                    {dealersFound} {dealersFound === 1 ? 'dealer' : 'dealers'} gevonden
                  </>
                )}
              </span>
            </div>
          </div>

          {enableSearch && (
            <DealerSearch
              dealersFound={locations.length}
              onSubmit={onDealerSearch}
              onGeolocationRequest={onGeolocationRequest}
              googleError={googleError}
              productGroup={productGroup}
              formType={formType}
              viewMode={viewMode}
              isFilteringMotor={isFilteringMotor}
              filter={filter}
              setFilter={setFilter}
              filterOptions={filterOptions}
            />
          )}

          <DealerList
            locations={locations}
            onDealerSelect={onDealerSelect}
            showDistances={showDistances}
            viewMode={viewMode}
            showInfo={showInfo}
            dealer={dealer}
            productGroup={productGroup}
            formType={formType}
            configuratorQueryString={configuratorQueryString}
            isModal={isModal}
          />
        </div>

        <div className="dealer-detail-info side-panel__page" ref={dealerInfoRef}>
          {showBackButton && (
            <SidePanelHeader
              overviewUrl={viewMode === 'contact_detail' && overviewUrl ? overviewUrl : null}
              onGoHome={onGoHome}
            />
          )}
          <DealerInfo
            overviewUrl={overviewUrl}
            isMultiple={locations.length > 1}
            viewMode={viewMode}
            dealerIndex={dealerIndex + 1}
            productGroup={productGroup}
            formType={formType}
            dealerDetailUrl={dealerDetailUrl}
            isModal={isModal}
            {...dealer}
          />
        </div>
      </div>
    </div>
  )
}

export default SidePanel
