import { createAction } from '@reduxjs/toolkit'
import queryString from 'query-string'
import { apiGet, apiGetEditionDetails, apiPostQuote } from '~src/api/client'
import { IEdition, IFinancialProduct, IModelProducts } from '~src/api/types/configurator'
import { RootState } from '~src/store'
import { defaultBasicConfiguration } from '~src/store/configuration/initialState'
import { ICarConfiguration, IConfigurationState } from '~src/store/configuration/types'
import {
  deserializeConfiguration,
  deserializeTradeInCar,
  deserializeTradeInNewCar,
} from '~src/store/url-serializers'
import {
  getDefaultDownpaymentPercentage,
  getStandardFinancialValues,
  regularTotalPrice,
} from '~src/utils/price'
import { ITradeInCar, ITradeInQuote, ITradeInSuggestion } from './trade-in/types'

// This is a special action only called once in the beginning after the first api call is done.
export const setInitialState = createAction<Omit<RootState, 'navigation'>>('initialize')

/**
 * Builds the initial state from the query parameters and the models api endpoint.
 * When an edition is also set in the query parameters, or when using a `financeModal`,
 * another api call for the edition is made.
 *
 * Navigation state is initialized elsewhere, so that we can show skeleton loaders
 * while the global initial state is building.
 */
export const buildInitialState = async ({
  modelsApiUrl,
  apiBaseUrl,
  financialProducts = [],
}: {
  modelsApiUrl: string
  apiBaseUrl?: string
  financialProducts?: IFinancialProduct[]
}): Promise<Omit<RootState, 'navigation'>> => {
  const models = (await apiGet<IModelProducts[]>(modelsApiUrl)).data

  const queryParams = queryString.extract(window.location.search || '')
  const oldCar = deserializeTradeInCar(queryParams)
  const newCar = deserializeTradeInNewCar(queryParams)

  // First we get a partial configuration state from the url.
  const urlConfiguration = deserializeConfiguration(queryParams, queryString.extract(modelsApiUrl))

  const model = urlConfiguration.model
    ? models.find((m) => m.code === urlConfiguration.model)
    : undefined

  const simpleEdition =
    model && urlConfiguration.car.edition
      ? model.editions.find((e) => e.code === urlConfiguration.car.edition)
      : undefined

  const [edition, financialData] = simpleEdition ? await apiGetEditionDetails(simpleEdition) : []

  const tradeInQuote = await fetchTradeInQuote(oldCar, newCar, apiBaseUrl)

  const car: ICarConfiguration = {
    accessoryCodes: [],
    packageCodes: [],
    upsellCodes: [],
    edition: urlConfiguration.car.edition ?? edition?.code,
    color: edition?.colors[0]?.code,
    ...urlConfiguration.car,
  }

  const initialState: Omit<RootState, 'navigation'> = {
    api: {
      edition: edition ?? null,
      editionFinancialData: financialData ?? null,
      models,
      financialProducts,
      occasion: null,
      usingFinancialPlan: null,
    },
    configuration: {
      productGroup: urlConfiguration.productGroup ?? 'car',
      model: urlConfiguration.model ?? model?.code,
      car,
      basic: {
        // We have some absolute basic configuration
        ...defaultBasicConfiguration,
        ...(edition
          ? // Then some default basic configuration specific for an edition
            defaultBasicConfigurationForEdition(
              {
                productGroup: urlConfiguration.productGroup ?? 'car',
                model: urlConfiguration.model ?? '',
                car,
                basic: { ...defaultBasicConfiguration, ...urlConfiguration.basic },
              },
              edition,
              model
            )
          : undefined),
        // And finally the current configuration state extracted from the url.
        // This should overwrite the default configuration(s)
        ...urlConfiguration.basic,
      },
    },
    comparison: { selectedCarCodes: [] },
    tradeIn: {
      status: null,
      oldCar,
      tradeInQuote,
      newCar,
      successMessage: null,
      customer: null,
    },
  }

  return initialState
}

export const defaultBasicConfigurationForEdition = (
  configuration: IConfigurationState,
  edition: IEdition,
  model: IModelProducts
) => {
  const totalPrice =
    configuration.basic.regularPriceOverride ?? regularTotalPrice(edition, configuration, model)
  const defaultDownPaymentPercentage = getDefaultDownpaymentPercentage(configuration.productGroup)
  const { standardFinalPayment } = getStandardFinancialValues(
    totalPrice,
    configuration.productGroup
  )

  return {
    downPayment: Math.floor(totalPrice * defaultDownPaymentPercentage),
    finalPayment: configuration.productGroup !== 'car' ? 0 : standardFinalPayment,
    creditOverride:
      configuration.productGroup !== 'car'
        ? Math.floor(totalPrice * (1 - defaultDownPaymentPercentage))
        : undefined,
  }
}

const fetchTradeInQuote = async (
  oldCar: ITradeInCar,
  newCar: ITradeInSuggestion,
  apiBaseUrl: string
): Promise<ITradeInQuote | null> => {
  if (!oldCar || !newCar || !apiBaseUrl) return null

  const { data } = await apiPostQuote({
    apiBaseUrl,
    oldCar,
    newCarCode: newCar.code,
    newCarEditionCode: newCar.edition.code,
  })

  return data ?? null
}
