import React, { lazy, Suspense, useEffect, useState } from 'react'
import Modal from '~src/common/Modal'
import { useRootState } from '~src/hooks/store'
import type { Props as ConfiguratorProps } from './Configurator'
import { useDispatch } from 'react-redux'
import { setEdition, setModel, updateBasicConfiguration } from '~src/store/configuration/actions'
import { apiGetEditionDetails } from '~src/api/client'
import { setFinancialData } from '~src/store/api/actions'
import { defaultBasicConfigurationForEdition } from '~src/store/initialization'
const Configurator = lazy(() => import('~src/apps/Configurator/Configurator'))

type Props = ConfiguratorProps & {
  text: string
  className: string
  plan: string
  modelCode?: string
}

function ConfiguratorModalButton({
  text,
  className,
  plan,
  modelCode,
  ...props
}: Props): JSX.Element {
  const { configuration, initialized, api } = useRootState()
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(Boolean(configuration.model))

  const handleClick = async (evt?: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()

    const model = api.models.find((m) => m.code === modelCode)
    const edition = model?.editions[0]

    const [editionDetails, financialData] = await apiGetEditionDetails(edition)

    dispatch(setModel(model))
    dispatch(setFinancialData(financialData))
    dispatch(
      updateBasicConfiguration(
        defaultBasicConfigurationForEdition(configuration, editionDetails, model)
      )
    )
    dispatch(setEdition(editionDetails))

    const url = `${window.location.pathname}?financeModal=true&plan=${plan}`
    window.history.replaceState(null, '', url)
    setIsOpen(true)
  }

  const afterClose = () => {
    window.history.replaceState(null, '', window.location.pathname)
    setIsOpen(false)
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search || '')

    if (configuration.car.edition && params.get('financeModal')) {
      setIsOpen(true)
    }
  }, [initialized])

  return (
    <>
      <button type="button" className={`button ${className}`} onClick={handleClick}>
        {text}
      </button>

      <Modal open={isOpen} onClose={afterClose} className="modal-lg-wide modal--extra-padding">
        <Suspense fallback={<span></span>}>
          <Configurator displayHeader={false} {...props} />
        </Suspense>
      </Modal>
    </>
  )
}

export default ConfiguratorModalButton
